import React from "react";
import HorizontalGauge from "./../components/react-horizontal-gauge/dist/react-horizontal-gauge";

import { FiThumbsDown, FiThumbsUp } from "react-icons/fi";

export const Scores = (props) => {
  
  const { data, data2 } = props;

  return (
    <div className="row justify-content-start scoreRoot col-12 col-sm-10">
      <div className="col-auto card-base text-center">
        <div className="card-title">Fundação anterior a 2 Anos</div>
        <div className="row justify-content-center">
          <div className="col-6">
            <div className="icon">
              {data?.fundacao_anterior_a_dois_anos &&
              data?.fundacao_anterior_a_dois_anos?.cedente ? (
                <FiThumbsDown className="card-icon iconError" />
              ) : (
                <FiThumbsUp className="card-icon iconOk" />
              )}
            </div>
            <div className="tipo">cedente</div>
          </div>
          <div className="col-6">
            <div className="icon">
              {data?.fundacao_anterior_a_dois_anos &&
              data?.fundacao_anterior_a_dois_anos?.sacado ? (
                <FiThumbsDown className="card-icon iconError" />
              ) : (
                <FiThumbsUp className="card-icon iconOk" />
              )}
            </div>
            <div className="tipo">sacado</div>
          </div>
        </div>
      </div>

      <div className="col-auto card-base text-center">
        <div className="card-title">Contagem Protesto</div>
        <div className="row justify-content-center">
          <div className="col-6">
            <div className="icon">
              {data?.contagem_protesto &&
              data?.contagem_protesto?.cedente &&
              data?.contagem_protesto?.cedente ? (
                <FiThumbsDown className="card-icon iconError" />
              ) : (
                <FiThumbsUp className="card-icon iconOk" />
              )}
            </div>
            <div className="tipo">cedente</div>
          </div>
          <div className="col-6">
            <div className="icon">
              {data?.contagem_protesto &&
              data?.contagem_protesto?.sacado &&
              data?.contagem_protesto?.sacado ? (
                <FiThumbsDown className="card-icon iconError" />
              ) : (
                <FiThumbsUp className="card-icon iconOk" />
              )}
            </div>
            <div className="tipo">sacado</div>
          </div>
        </div>
      </div>

      <div className="col-auto card-base text-center">
        <div className="card-title">Match Endereco</div>
        <div className="icon">
          {data?.cep_math ? (
            <FiThumbsDown className="card-icon bigIconError iconError" />
          ) : (
            <FiThumbsUp className="card-icon iconOk bigIconOK" />
          )}
        </div>
      </div>

      <div className="col-auto card-base text-center">
        <div className="card-title">Distância CNAEs</div>
        <div className="icon">
          <HorizontalGauge
            height={70}
            width={350}
            min={0}
            max={1}
            value={data?.distancia_entre_cnaes}
            ticks={[
              { label: "0", value: 0 },
              { label: "0.25", value: 0.25 },
              { label: "0.5", value: 0.5 },
              { label: "0.75", value: 0.75 },
              { label: "1", value: 1 },
            ]}
          />
        </div>
      </div>

      {/* <div className="col-auto card-base text-center">
        <div className="card-title">Contagem Protesto</div>
        <div className="row justify-content-center">
          <div className="col-6">
            <div className="icon">
              {data?.contagem_proteto && data?.contagem_proteto.cedente ? (
                <FiThumbsDown className="card-icon iconError" />
              ) : (
                <FiThumbsUp className="card-icon iconOk" />
              )}
            </div>
            <div className="tipo">cedente</div>
          </div>
          <div className="col-6">
            <div className="icon">
              {data?.contagem_proteto && data?.contagem_proteto.sacado ? (
                <FiThumbsDown className="card-icon iconError" />
              ) : (
                <FiThumbsUp className="card-icon iconOk" />
              )}
            </div>
            <div className="tipo">sacado</div>
          </div>
        </div>
      </div> */}

      <div className="col-auto card-base text-center">
        <div className="card-title">Sócios Similares</div>
        <div className="icon">
          {data?.socios_similares ? (
            <FiThumbsDown className="card-icon bigIconError iconError" />
          ) : (
            <FiThumbsUp className="card-icon iconOk bigIconOK" />
          )}
        </div>
      </div>

      <div className="col-auto card-base text-center">
        <div className="card-title">Match Telefone</div>
        <div className="row justify-content-center">
          <div className="col-6">
            <div className="icon">
              {data?.tel1_match ? (
                <FiThumbsDown className="card-icon iconError" />
              ) : (
                <FiThumbsUp className="card-icon iconOk" />
              )}
            </div>
            <div className="tipo">cedente</div>
          </div>
          <div className="col-6">
            <div className="icon">
              {data?.tel2_match ? (
                <FiThumbsDown className="card-icon iconError" />
              ) : (
                <FiThumbsUp className="card-icon iconOk" />
              )}
            </div>
            <div className="tipo">sacado</div>
          </div>
        </div>
      </div>

      <div className="col-auto card-base text-center">
        <div className="card-title">Tempo Médio Sócios</div>
        <div className="row justify-content-center">
          <div className="col-6">
            <div className="icon">
              {data?.tempo_medio_socios_anterior_a_dois_anos &&
              data?.tempo_medio_socios_anterior_a_dois_anos.cedente &&
              data?.tempo_medio_socios_anterior_a_dois_anos.cedente ? (
                <FiThumbsDown className="card-icon iconError" />
              ) : (
                <FiThumbsUp className="card-icon iconOk" />
              )}
            </div>
            <div className="tipo">cedente</div>
          </div>
          <div className="col-6">
            <div className="icon">
              {data?.tempo_medio_socios_anterior_a_dois_anos &&
              data?.tempo_medio_socios_anterior_a_dois_anos.sacado &&
              data?.tempo_medio_socios_anterior_a_dois_anos.sacado ? (
                <FiThumbsDown className="card-icon iconError" />
              ) : (
                <FiThumbsUp className="card-icon iconOk" />
              )}
            </div>
            <div className="tipo">sacado</div>
          </div>
        </div>
      </div>

      <div className="col-auto card-base text-center">
        <div className="card-title">Warning Capital Social</div>
        <div className="row justify-content-center">
          <div className="col-6">
            <div className="icon">
              {data?.warning_capital_social &&
              data?.warning_capital_social.cedente &&
              data?.warning_capital_social.cedente ? (
                <FiThumbsDown className="card-icon iconError" />
              ) : (
                <FiThumbsUp className="card-icon iconOk" />
              )}
            </div>
            <div className="tipo">cedente</div>
          </div>
          <div className="col-6">
            <div className="icon">
              {data?.warning_capital_social &&
              data?.warning_capital_social.sacado &&
              data?.warning_capital_social.sacado ? (
                <FiThumbsDown className="card-icon iconError" />
              ) : (
                <FiThumbsUp className="card-icon iconOk" />
              )}
            </div>
            <div className="tipo">sacado</div>
          </div>
        </div>
      </div>

      <div className="col-auto card-base text-center">
        <div className="card-title">Situação Cadastral</div>
        <div className="row justify-content-center">
          <div className="col-6">
            <div className="icon">
              {data2?.cedente?.receita_federal.pesquisa_basica.cadastro
                .situacao_cadastral !== "02" ? (
                <FiThumbsDown className="card-icon iconError" />
              ) : (
                <FiThumbsUp className="card-icon iconOk" />
              )}
            </div>
            <div className="tipo">cedente</div>
          </div>
          <div className="col-6">
            <div className="icon">
              {data2?.sacado?.receita_federal.pesquisa_basica.cadastro
                .situacao_cadastral !== "02" ? (
                <FiThumbsDown className="card-icon iconError" />
              ) : (
                <FiThumbsUp className="card-icon iconOk" />
              )}
            </div>
            <div className="tipo">sacado</div>
          </div>
        </div>
      </div>

      <div className="col-auto card-base text-center">
        <div className="card-title">Cheques Sem Fundo?</div>
        <div className="row justify-content-center">
          <div className="col-6">
            <div className="icon">
              {data2?.cedente?.cheque_sem_fundo &&
              data2?.cedente?.cheque_sem_fundo.temCheques ? (
                <FiThumbsDown className="card-icon iconError" />
              ) : (
                <FiThumbsUp className="card-icon iconOk" />
              )}
            </div>
            <div className="tipo">cedente</div>
          </div>
          <div className="col-6">
            <div className="icon">
              {data2?.sacado?.cheque_sem_fundo &&
              data2?.sacado?.cheque_sem_fundo.temCheques ? (
                <FiThumbsDown className="card-icon iconError" />
              ) : (
                <FiThumbsUp className="card-icon iconOk" />
              )}
            </div>
            <div className="tipo">sacado</div>
          </div>
        </div>
      </div>
    </div>
  );
};
