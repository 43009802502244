import React from "react";

export const Processos = (props) => {
  
  const { data } = props;

  return (
    <div className="row justify-content-start text-left col-12 bncardFields">
      <div className="subsection col-12">
        <div className="row justify-content-center text-center">
          <div className="field">
            <div className="label">Justiça do Trabalho</div>
            <div className="value valueBig">
              {data?.justica_do_trabalho.toLocaleString("pt-BR")}
            </div>
          </div>

          <div className="field">
            <div className="label">Justiça Estadual</div>
            <div className="value valueBig">
              {data?.justica_estadual.toLocaleString("pt-BR")}
            </div>
          </div>

          <div className="field">
            <div className="label">Outros</div>
            <div className="value valueBig">
              {data?.outros.toLocaleString("pt-BR")}
            </div>
          </div>

          <div
            className="field"
            style={{
              background: "#68bf8e none repeat scroll 0% 0%",
              color: "white",
              paddingBottom: "16px",
              borderRadius: "8px",
            }}
          >
            <div className="label" style={{ color: "white" }}>
              Total
            </div>
            <div className="value valueBig" style={{ color: "white" }}>
              {data?.total && typeof data?.total !== "object" ? data?.total : 0}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
