import React from 'react';
import { IoIosArrowForward } from 'react-icons/io';

export const SociosDados = (props) => {
	const { data } = props;
	const [ show, setShow ] = React.useState(false);

	return (
		<div className="subsection col-12 sociosDados">
			<br />
			<div className="row justify-content-start align-items-center" onClick={() => setShow(!show)}>
				<h3 className="col-auto nameCompany">{data.cadastro.razao_social}</h3>
				{data.cadastro.nome_fantasia && <IoIosArrowForward className="col-auto nameCompanyIcon" />}
				{data.cadastro.nome_fantasia && <h3 className="col-auto nameCompany">{data.cadastro.nome_fantasia}</h3>}
			</div>

			{show && (
				<div className="fields">
					<div className="field">
						<div className="label">Bairro</div>
						<div className="value">{data.cadastro.bairro}</div>
					</div>

					<div className="field">
						<div className="label">Capital Social da Empresa</div>
						<div className="value">{data.cadastro.capital_social_empresa}</div>
					</div>

					<div className="field">
						<div className="label">CEP</div>
						<div className="value">{data.cadastro.cep}</div>
					</div>

					<div className="field">
						<div className="label">CNAE Fiscal</div>
						<div className="value">{data.cadastro.cnae_fiscal}</div>
					</div>

					<div className="field">
						<div className="label">CNPJ</div>
						<div className="value">{data.cadastro.cnpj}</div>
					</div>

					<div className="field">
						<div className="label">Código do País</div>
						<div className="value">{data.cadastro.cod_pais}</div>
					</div>

					<div className="field">
						<div className="label">Código do Município</div>
						<div className="value">{data.cadastro.codigo_municipio}</div>
					</div>

					<div className="field">
						<div className="label">Código da Natureza Jurídica</div>
						<div className="value">{data.cadastro.codigo_natureza_juridica}</div>
					</div>

					<div className="field">
						<div className="label">Complemento</div>
						<div className="value">{data.cadastro.complemento}</div>
					</div>

					<div className="field">
						<div className="label">Correio Eletrônico</div>
						<div className="value" style={{ textTransform: 'lowercase' }}>
							{data.cadastro.correio_eletronico}
						</div>
					</div>

					<div className="field">
						<div className="label">Data de Exclusão do Simples</div>
						<div className="value">{data.cadastro.data_exclusao_simples}</div>
					</div>

					<div className="field">
						<div className="label">Data Início de Atividades</div>
						<div className="value">{data.cadastro.data_inicio_atividade}</div>
					</div>

					<div className="field">
						<div className="label">Data Opção pelo simples</div>
						<div className="value">{data.cadastro.data_opcao_pelo_simples}</div>
					</div>

					<div className="field">
						<div className="label">data situação cadastral</div>
						<div className="value">{data.cadastro.data_situacao_cadastral}</div>
					</div>

					<div className="field">
						<div className="label">data situação especial</div>
						<div className="value">{data.cadastro.data_situacao_especial}</div>
					</div>

					<div className="field">
						<div className="label">ddd fax</div>
						<div className="value">{data.cadastro.ddd_fax}</div>
					</div>

					<div className="field">
						<div className="label">Telefone 1</div>
						<div className="value">{data.cadastro.ddd_telefone_1}</div>
					</div>

					<div className="field">
						<div className="label">Telefone 2</div>
						<div className="value">{data.cadastro.ddd_telefone_2}</div>
					</div>

					<div className="field">
						<div className="label">Logradouro</div>
						<div className="value">{data.cadastro.descricao_tipo_logradouro}</div>
					</div>

					<div className="field">
						<div className="label">Filler</div>
						<div className="value">{data.cadastro.filler}</div>
					</div>

					<div className="field">
						<div className="label">Fim Registro</div>
						<div className="value">{data.cadastro.fim_registro}</div>
					</div>

					<div className="field">
						<div className="label">identificador matriz filial</div>
						<div className="value">{data.cadastro.identificador_matriz_filial}</div>
					</div>

					<div className="field">
						<div className="label">indicador</div>
						<div className="value">{data.cadastro.indicador}</div>
					</div>

					<div className="field">
						<div className="label">logradouro</div>
						<div className="value">{data.cadastro.logradouro}</div>
					</div>

					<div className="field">
						<div className="label">Motivo Situação Cadastral</div>
						<div className="value">{data.cadastro.motivo_situacao_cadastral}</div>
					</div>

					<div className="field">
						<div className="label">Município</div>
						<div className="value">{data.cadastro.municipio}</div>
					</div>

					<div className="field">
						<div className="label">Nome Cidade Exterior</div>
						<div className="value">{data.cadastro.nm_cidade_exterior}</div>
					</div>

					<div className="field">
						<div className="label">País</div>
						<div className="value">{data.cadastro.nm_pais}</div>
					</div>

					<div className="field">
						<div className="label">Nome Fantasia</div>
						<div className="value">{data.cadastro.nome_fantasia}</div>
					</div>

					<div className="field">
						<div className="label">Número</div>
						<div className="value">{data.cadastro.numero}</div>
					</div>

					<div className="field">
						<div className="label">Opção pelo MEI</div>
						<div className="value">{data.cadastro.opcao_pelo_mei}</div>
					</div>

					<div className="field">
						<div className="label">Opção pelo Simples</div>
						<div className="value">{data.cadastro.opcao_pelo_simples}</div>
					</div>

					<div className="field">
						<div className="label">Porte da Empresa</div>
						<div className="value">{data.cadastro.porte_empresa}</div>
					</div>

					<div className="field">
						<div className="label">Qualificação do Responsável</div>
						<div className="value">{data.cadastro.qualificacao_responsavel}</div>
					</div>

					<div className="field">
						<div className="label">Razão Socal</div>
						<div className="value">{data.cadastro.razao_social}</div>
					</div>

					<div className="field">
						<div className="label">Situação Cadastral</div>
						<div className="value">{data.cadastro.situacao_cadastral}</div>
					</div>

					<div className="field">
						<div className="label">Situação Especial</div>
						<div className="value">{data.cadastro.situacao_especial}</div>
					</div>

					<div className="field">
						<div className="label">Tipo Atualização</div>
						<div className="value">{data.cadastro.tipo_atualizacao}</div>
					</div>

					<div className="field">
						<div className="label">Tipo de Registro</div>
						<div className="value">{data.cadastro.tipo_de_registro}</div>
					</div>
					<div className="field">
						<div className="label">UF</div>
						<div className="value">{data.cadastro.uf}</div>
					</div>
				</div>
			)}

			{show && <div className="divisor" />}

			{show && (
				<div className="fields col-12" style={{ marginTop: '10px' }}>
					<div
						className="label"
						style={{
							fontWeight: 'bolder',
							textTransform: 'uppercase',
							fontSize: '12px',
							marginBottom: '5px'
						}}
					>
						Sócios
					</div>

					<table className="table table-striped table-sm">
						<thead>
							<tr>
								<th scope="col">CNPJ/CPF</th>
								<th scope="col">Data de Ingresso</th>
								<th scope="col">Identificador</th>
								<th scope="col">Nome</th>
								<th scope="col">Qualificador</th>
							</tr>
						</thead>
						<tbody>
							{data.socios.map((item, key) => (
								<tr key={key}>
									<td>{item.cnpj_cpf_socio}</td>
									<td>{item.data_entrada_sociedade}</td>
									<td>{item.identificador_socio}</td>
									<td>{item.nome_socio}</td>
									<td>{item.qualificacao_socio}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)}

			{show && <div className="divisor" />}

			{show && (
				<div className="fields col-12" style={{ marginTop: '10px' }}>
					<div
						className="label"
						style={{
							fontWeight: 'bolder',
							textTransform: 'uppercase',
							fontSize: '12px',
							marginBottom: '5px'
						}}
					>
						Ramo de Atividade
					</div>

					<table className="table table-striped table-sm">
						<thead>
							<tr>
								<th scope="col">Aliquota Máx</th>
								<th scope="col">Alíquota Min</th>
								<th scope="col">Anexo</th>
								<th scope="col">Código</th>
								<th scope="col">Compreende</th>
							</tr>
						</thead>
						<tbody>
							{data.ramo_de_atividade && (
								<tr>
									<td> {data.ramo_de_atividade.AliquotaMax && data.ramo_de_atividade.AliquotaMax}</td>
									<td> {data.ramo_de_atividade.AliquotaMin && data.ramo_de_atividade.AliquotaMin}</td>
									<td> {data.ramo_de_atividade.Anexo && data.ramo_de_atividade.Anexo}</td>
									<td> {data.ramo_de_atividade.Codigo && data.ramo_de_atividade.Codigo}</td>
									<td> {data.ramo_de_atividade.Compreende && data.ramo_de_atividade.Compreende}</td>
								</tr>
							)}
							<tr>
								<td colSpan={5}>
									<div
										className="label"
										style={{
											textTransform: 'uppercase',
											fontWeight: 'bold',
											fontSize: '12px',
											marginTop: '10px'
										}}
									>
										Não Compreende
									</div>
									<div
										className="value"
										style={{
											fontSize: '10px',
											lineHeight: '1.2em',
											marginTop: '10px'
										}}
									>
										{data.ramo_de_atividade &&
											data.ramo_de_atividade.N_Compreende &&
											data.ramo_de_atividade.N_Compreende}
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			)}
		</div>
	);
};
