import React from "react";
import InputMask from "react-input-mask";
import { BiRightArrow } from "react-icons/bi";

export const InputHeader = (props) => {
  
  const [data, setData] = React.useState({ sacado: "", cedente: "" });

  return (
    <div className="fields row justify-content-center no-gutters" id="cnpjList">
      <div className="col-7 row justify-content-center align-items-center">
        <div className="form-group col-5 text-center">
          <label>Cedente</label>
          <InputMask
            mask="99.999.999/9999-99"
            className="form-control cnpjField"
            placeholder="CNPJ do Cedente"
            value={data.cedente}
            onChange={(evt) => setData({ ...data, cedente: evt.target.value })}
          />
        </div>

        <BiRightArrow className="col-1 iconArrow" />

        <div className="form-group col-5 text-center">
          <label>Sacado</label>
          <InputMask
            mask="99.999.999/9999-99"
            className="form-control cnpjField"
            placeholder="CNPJ do Sacado"
            value={data.sacado}
            onChange={(evt) => setData({ ...data, sacado: evt.target.value })}
          />
        </div>

        <button
          className="btn btn-primary btn-sm col-1 btnPesquisar"
          onClick={() => props.loadAPI(data.sacado, data.cedente)}
        >
          OK
        </button>
      </div>
    </div>
  );
};
