import React from "react";
import { SociosDados } from "./../components/sociosDados";

export const ReceitaFederal = (props) => {
  
  const { data } = props;

  const matchEndereco = () => {
    const dataTemp = data?.scores?.match_endereco
      ? Object.entries(data?.scores?.match_endereco)
      : [];

    return (
      <div>
        {dataTemp.map((item, key) => (
          <div className="row justify-content-start matchRow" key={key}>
            <div className="label col-8 text-right">{item[0]}</div>
            <ul className="value col-4 ">
              {item[1].map((itemCNPJ, keyA) => (
                <li key={keyA} className="matchCNPJ">
                  {itemCNPJ}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    );
  };

  const matchEmail = () => {
    
    const dataTemp = data?.scores?.match_email
      ? Object.entries(data?.scores?.match_email)
      : [];

    return (
      <div>
        {dataTemp.map((item, key) => (
          <div className="row justify-content-start matchRow" key={key}>
            <div className="label col-8 text-right">{item[0]}</div>
            <ul className="value col-4">
              {item[1].map((itemCNPJ, keyA) => (
                <li key={keyA} className="matchCNPJ">
                  {itemCNPJ}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    );
  };

  const matchDominio = () => {
    const dataTemp = data?.scores?.match_dominio_email
      ? Object.entries(data?.scores?.match_dominio_email)
      : [];

    return (
      <div>
        {dataTemp?.map((item, key) => (
          <div className="row justify-content-start matchRow" key={key}>
            <div className="label col-8 text-right">{item[0]}</div>
            <ul className="value col-4">
              {item[1].map((itemCNPJ, keyA) => (
                <li key={keyA} className="matchCNPJ">
                  {itemCNPJ}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="row justify-content-start text-left col-12">
      <div className="subsection col-12">
        <h3>Pesquisa Básica</h3>
        <div className="fields">
          <div className="field">
            <div className="label">Bairro</div>
            <div className="value">{data?.pesquisa_basica?.cadastro?.bairro}</div>
          </div>

          <div className="field">
            <div className="label">Capital Social da Empresa</div>
            <div className="value">
              {new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(data?.pesquisa_basica?.cadastro?.capital_social_empresa)}
            </div>
          </div>

          <div className="field">
            <div className="label">CEP</div>
            <div className="value">{data?.pesquisa_basica?.cadastro?.cep}</div>
          </div>

          <div className="field">
            <div className="label">CNAE Fiscal</div>
            <div className="value">
              {data?.pesquisa_basica?.cadastro?.cnae_fiscal}
            </div>
          </div>

          <div className="field">
            <div className="label">CNPJ</div>
            <div className="value">{data?.pesquisa_basica?.cadastro?.cnpj}</div>
          </div>

          <div className="field">
            <div className="label">Código do País</div>
            <div className="value">
              {data?.pesquisa_basica?.cadastro?.cod_pais}
            </div>
          </div>

          <div className="field">
            <div className="label">Código do Município</div>
            <div className="value">
              {data?.pesquisa_basica?.cadastro?.codigo_municipio}
            </div>
          </div>

          <div className="field">
            <div className="label">Código da Natureza Jurídica</div>
            <div className="value">
              {data?.pesquisa_basica?.cadastro?.codigo_natureza_juridica}
            </div>
          </div>

          <div className="field">
            <div className="label">Complemento</div>
            <div className="value">
              {data?.pesquisa_basica?.cadastro?.complemento}
            </div>
          </div>

          <div className="field">
            <div className="label">Correio Eletrônico</div>
            <div className="value" style={{ textTransform: "lowercase" }}>
              {data?.pesquisa_basica?.cadastro?.correio_eletronico}
            </div>
          </div>

          <div className="field">
            <div className="label">Data de Exclusão do Simples</div>
            <div className="value">
              {data?.pesquisa_basica?.cadastro?.data_exclusao_simples}
            </div>
          </div>

          <div className="field">
            <div className="label">Data Início de Atividades</div>
            <div className="value">
              {data?.pesquisa_basica?.cadastro?.data_inicio_atividade}
            </div>
          </div>

          <div className="field">
            <div className="label">Data Opção pelo simples</div>
            <div className="value">
              {data?.pesquisa_basica?.cadastro?.data_opcao_pelo_simples}
            </div>
          </div>

          <div className="field">
            <div className="label">data situação cadastral</div>
            <div className="value">
              {data?.pesquisa_basica?.cadastro?.data_situacao_cadastral}
            </div>
          </div>

          <div className="field">
            <div className="label">data situação especial</div>
            <div className="value">
              {data?.pesquisa_basica?.cadastro?.data_situacao_especial}
            </div>
          </div>

          <div className="field">
            <div className="label">ddd fax</div>
            <div className="value">{data?.pesquisa_basica?.cadastro?.ddd_fax}</div>
          </div>

          <div className="field">
            <div className="label">Telefone 1</div>
            <div className="value">
              {data?.pesquisa_basica?.cadastro?.ddd_telefone_1}
            </div>
          </div>

          <div className="field">
            <div className="label">Telefone 2</div>
            <div className="value">
              {data?.pesquisa_basica?.cadastro?.ddd_telefone_2}
            </div>
          </div>

          <div className="field">
            <div className="label">Logradouro</div>
            <div className="value">
              {data?.pesquisa_basica?.cadastro?.descricao_tipo_logradouro}
            </div>
          </div>

          <div className="field">
            <div className="label">Filler</div>
            <div className="value">{data?.pesquisa_basica?.cadastro?.filler}</div>
          </div>

          <div className="field">
            <div className="label">Fim Registro</div>
            <div className="value">
              {data?.pesquisa_basica?.cadastro?.fim_registro}
            </div>
          </div>

          <div className="field">
            <div className="label">identificador matriz filial</div>
            <div className="value">
              {data?.pesquisa_basica?.cadastro?.identificador_matriz_filial}
            </div>
          </div>

          <div className="field">
            <div className="label">indicador</div>
            <div className="value">
              {data?.pesquisa_basica?.cadastro?.indicador}
            </div>
          </div>

          <div className="field">
            <div className="label">logradouro</div>
            <div className="value">
              {data?.pesquisa_basica?.cadastro?.logradouro}
            </div>
          </div>

          <div className="field">
            <div className="label">Motivo Situação Cadastral</div>
            <div className="value">
              {data?.pesquisa_basica?.cadastro?.motivo_situacao_cadastral}
            </div>
          </div>

          <div className="field">
            <div className="label">Município</div>
            <div className="value">
              {data?.pesquisa_basica?.cadastro?.municipio}
            </div>
          </div>

          <div className="field">
            <div className="label">Nome Cidade Exterior</div>
            <div className="value">
              {data?.pesquisa_basica?.cadastro?.nm_cidade_exterior}
            </div>
          </div>

          <div className="field">
            <div className="label">País</div>
            <div className="value">{data?.pesquisa_basica?.cadastro?.nm_pais}</div>
          </div>

          <div className="field">
            <div className="label">Nome Fantasia</div>
            <div className="value">
              {data?.pesquisa_basica?.cadastro?.nome_fantasia}
            </div>
          </div>

          <div className="field">
            <div className="label">Número</div>
            <div className="value">{data?.pesquisa_basica?.cadastro?.numero}</div>
          </div>

          <div className="field">
            <div className="label">Opção pelo MEI</div>
            <div className="value">
              {data?.pesquisa_basica?.cadastro?.opcao_pelo_mei}
            </div>
          </div>

          <div className="field">
            <div className="label">Opção pelo Simples</div>
            <div className="value">
              {data?.pesquisa_basica?.cadastro?.opcao_pelo_simples}
            </div>
          </div>

          <div className="field">
            <div className="label">Porte da Empresa</div>
            <div className="value">
              {data?.pesquisa_basica?.cadastro?.porte_empresa}
            </div>
          </div>

          <div className="field">
            <div className="label">Qualificação do Responsável</div>
            <div className="value">
              {data?.pesquisa_basica?.cadastro?.qualificacao_responsavel}
            </div>
          </div>

          <div className="field">
            <div className="label">Razão Socal</div>
            <div className="value">
              {data?.pesquisa_basica?.cadastro?.razao_social}
            </div>
          </div>

          <div className="field">
            <div className="label">Situação Cadastral</div>
            <div className="value">
              {data?.pesquisa_basica?.cadastro?.situacao_cadastral}
            </div>
          </div>

          <div className="field">
            <div className="label">Situação Especial</div>
            <div className="value">
              {data?.pesquisa_basica?.cadastro?.situacao_especial}
            </div>
          </div>

          <div className="field">
            <div className="label">Tipo Atualização</div>
            <div className="value">
              {data?.pesquisa_basica?.cadastro?.tipo_atualizacao}
            </div>
          </div>

          <div className="field">
            <div className="label">Tipo de Registro</div>
            <div className="value">
              {data?.pesquisa_basica?.cadastro?.tipo_de_registro}
            </div>
          </div>
          <div className="field">
            <div className="label">UF</div>
            <div className="value">{data?.pesquisa_basica?.cadastro?.uf}</div>
          </div>
        </div>

        <div className="divisor" />

        <div className="fields col-12" style={{ marginTop: "10px" }}>
          <div
            className="label"
            style={{
              fontWeight: "bolder",
              textTransform: "uppercase",
              fontSize: "12px",
              marginBottom: "5px",
            }}
          >
            Sócios
          </div>

          <table className="table table-striped table-sm">
            <thead>
              <tr>
                <th scope="col">CNPJ/CPF</th>
                <th scope="col">Data de Ingresso</th>
                <th scope="col">Identificador</th>
                <th scope="col">Nome</th>
                <th scope="col">Qualificador</th>
              </tr>
            </thead>
            <tbody>
              {data?.pesquisa_basica?.socios?.map((item, key) => (
                <tr key={key}>
                  <td>{item?.cnpj_cpf_socio}</td>
                  <td>{item?.data_entrada_sociedade}</td>
                  <td>{item?.identificador_socio}</td>
                  <td>{item?.nome_socio}</td>
                  <td>{item?.qualificacao_socio}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="divisor" />

        <div className="fields col-12" style={{ marginTop: "10px" }}>
          <div
            className="label"
            style={{
              fontWeight: "bolder",
              textTransform: "uppercase",
              fontSize: "12px",
              marginBottom: "5px",
            }}
          >
            Ramo de Atividade
          </div>

          <table className="table table-striped table-sm">
            <thead>
              <tr>
                <th scope="col">Aliquota Máx</th>
                <th scope="col">Alíquota Min</th>
                <th scope="col">Anexo</th>
                <th scope="col">Código</th>
                <th scope="col">Compreende</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {" "}
                  {data?.pesquisa_basica?.ramo_de_atividade &&
                    data?.pesquisa_basica?.ramo_de_atividade.AliquotaMax}
                </td>
                <td>
                  {" "}
                  {data?.pesquisa_basica?.ramo_de_atividade &&
                    data?.pesquisa_basica?.ramo_de_atividade.AliquotaMin}
                </td>
                <td>
                  {" "}
                  {data?.pesquisa_basica?.ramo_de_atividade &&
                    data?.pesquisa_basica?.ramo_de_atividade.Anexo}
                </td>
                <td>
                  {" "}
                  {data?.pesquisa_basica?.ramo_de_atividade &&
                    data?.pesquisa_basica?.ramo_de_atividade.Codigo}
                </td>
                <td>
                  {" "}
                  {data?.pesquisa_basica?.ramo_de_atividade &&
                    data?.pesquisa_basica?.ramo_de_atividade.Compreende}
                </td>
              </tr>
              <tr>
                <td colSpan={5}>
                  <div
                    className="label"
                    style={{
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      fontSize: "12px",
                      marginTop: "10px",
                    }}
                  >
                    Não Compreende
                  </div>
                  <div
                    className="value"
                    style={{
                      fontSize: "10px",
                      lineHeight: "1.2em",
                      marginTop: "10px",
                    }}
                  >
                    {data?.pesquisa_basica?.ramo_de_atividade &&
                      data?.pesquisa_basica?.ramo_de_atividade.N_Compreende}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="divisor" />

      <div className="subsection col-12">
        <h3>Grupo Societário</h3>
        <div className="socios">
          {data?.grupo_societario.map((socio, key) => (
            <SociosDados key={key} data={socio} />
          ))}
        </div>
      </div>

      <div className="divisor" />

      <div className="subsection col-12">
        <h3>Matches</h3>
        <div className="mathRoot row justify-content-between" style={{overflowY: "auto", height: "400px"}}>
          <div className="col-4 text-center">
            <h4 className="subtitle">Match Endereço</h4>
            {matchEndereco()}
          </div>

          <div className="col-4 text-center">
            <h4 className="subtitle">Match E-mail</h4>
            {matchEmail()}
          </div>

          <div className="col-4 text-center">
            <h4 className="subtitle">Match Domínio E-mail</h4>
            {matchDominio()}
          </div>
        </div>
      </div>
    </div>
  );
};
