import React from "react";

export const PGFN = (props) => {
  
  const { data } = props;

  return (
    <div className="row justify-content-start text-left col-12 bncardFields">
      <div className="subsection col-12">
        <div className="row justify-content-center text-center">
          {data?.DadosAbertosFGTS && (
            <div className="field">
              <div className="label">Dados Abertos - FGTS</div>
              <div className="value valueBig">
                {data?.DadosAbertosFGTS ? data?.DadosAbertosFGTS.length : "N/D"}
              </div>
            </div>
          )}

          {data?.DadosAbertosNaoPrevidenciario && (
            <div className="field">
              <div className="label">Dados Abertos - Não Previdenciário</div>
              <div className="value valueBig">
                {data?.DadosAbertosNaoPrevidenciario
                  ? data?.DadosAbertosNaoPrevidenciario.length
                  : "N/D"}
              </div>
            </div>
          )}

          {data?.DadosAbertosPrevidenciario && (
            <div className="field">
              <div className="label">Dados Abertos - Previdenciário</div>
              <div className="value valueBig">
                {data?.DadosAbertosPrevidenciario
                  ? data?.DadosAbertosPrevidenciario.length
                  : "N/D"}
              </div>
            </div>
          )}
        </div>

        {data?.DadosAbertosFGTS && data?.DadosAbertosFGTS.length > 0 && (
          <div className="row justify-content-start col-12 no-gutters">
            <div
              className="label col-12"
              style={{
                fontWeight: "bolder",
                textTransform: "uppercase",
                fontSize: "12px",
                marginBottom: "5px",
              }}
            >
              Dados Abertos FGTS
            </div>

            <table className="table table-sm table-striped tableFGTS table-responsive">
              <thead className="thead-dark">
                <td>CPF/CNPJ</td>
                <td>Data da Inscrição</td>
                <td>Responsável</td>
                <td>Indicador Ajuizado</td>
                <td>Nome do Devedor</td>
                <td>Inscrição</td>
                <td>Receita</td>
                <td>Situação</td>
                <td>Tipo Devedor</td>
                <td>Tipo Pessoa</td>
                <td>Tipo Situação</td>
                <td>UF</td>
                <td>Unidade Inscrição</td>
                <td>Unidade Responsável</td>
                <td>Valor Consolidado</td>
              </thead>
              <tbody>
                {data?.DadosAbertosFGTS &&
                  data?.DadosAbertosFGTS.length > 0 &&
                  data?.DadosAbertosFGTS.map((item, key) => (
                    <tr key={key}>
                      <td>{item?.cpf_cnpj}</td>
                      <td>{item?.data_inscricao}</td>
                      <td>{item?.entidade_responsavel}</td>
                      <td>{item?.indicador_ajuizado}</td>
                      <td>{item?.nome_devedor}</td>
                      <td>{item?.numero_inscricao}</td>
                      <td>{item?.receita_principal}</td>
                      <td>{item?.situacao_inscricao}</td>
                      <td>{item?.tipo_devedor}</td>
                      <td>{item?.tipo_pessoa}</td>
                      <td>{item?.tipo_situacao_inscricao}</td>
                      <td>{item?.uf_unidade_responsavel}</td>
                      <td>{item?.unidade_inscricao}</td>
                      <td>{item?.unidade_responsavel}</td>
                      <td>
                        {new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }).format(item?.VALOR_CONSOLIDADO)}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        {data?.DadosAbertosNaoPrevidenciario &&
          data?.DadosAbertosNaoPrevidenciario.length > 0 && (
            <div className="row justify-content-start col-12 no-gutters">
              <div
                className="label col-12"
                style={{
                  fontWeight: "bolder",
                  textTransform: "uppercase",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                Dados Abertos - Não Previdenciário
              </div>

              <table className="table table-sm table-striped tableFGTS table-responsive">
                <thead className="thead-dark">
                  <td>CPF/CNPJ</td>
                  <td>Data da Inscrição</td>
                  <td>Indicador Ajuizado</td>
                  <td>Nome do Devedor</td>
                  <td>Inscrição</td>
                  <td>Receita</td>
                  <td>Situação</td>
                  <td>Tipo Devedor</td>
                  <td>Tipo Pessoa</td>
                  <td>Tipo Situação</td>
                  <td>UF</td>
                  <td>Unidade Responsável</td>
                  <td>Valor Consolidado</td>
                </thead>
                <tbody>
                  {data?.DadosAbertosNaoPrevidenciario &&
                    data?.DadosAbertosNaoPrevidenciario.length > 0 &&
                    data?.DadosAbertosNaoPrevidenciario.map((item, key) => (
                      <tr key={key}>
                        <td>{item?.cpf_cnpj}</td>
                        <td>{item?.data_inscricao}</td>
                        <td>{item?.indicador_ajuizado}</td>
                        <td>{item?.nome_devedor}</td>
                        <td>{item?.numero_inscricao}</td>
                        <td>{item?.receita_principal}</td>
                        <td>{item?.situacao_inscricao}</td>
                        <td>{item?.tipo_devedor}</td>
                        <td>{item?.tipo_pessoa}</td>
                        <td>{item?.tipo_situacao_inscricao}</td>
                        <td>{item?.uf_unidade_responsavel}</td>
                        <td>{item?.unidade_responsavel}</td>
                        <td>
                          {new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(item?.valor_consolidado)}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}

        {data?.DadosAbertosPrevidenciario &&
          data?.DadosAbertosPrevidenciario.length > 0 && (
            <div className="row justify-content-start col-12 no-gutters">
              <div
                className="label col-12"
                style={{
                  fontWeight: "bolder",
                  textTransform: "uppercase",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                Dados Abertos - Previdenciário
              </div>

              <table className="table table-sm table-striped tableFGTS table-responsive">
                <thead className="thead-dark">
                  <td>CPF/CNPJ</td>
                  <td>Data da Inscrição</td>
                  <td>Indicador Ajuizado</td>
                  <td>Nome do Devedor</td>
                  <td>Inscrição</td>
                  <td>Receita</td>
                  <td>Situação</td>
                  <td>Tipo Devedor</td>
                  <td>Tipo Pessoa</td>
                  <td>Tipo Situação</td>
                  <td>UF</td>
                  <td>Unidade Responsável</td>
                  <td>Valor Consolidado</td>
                </thead>
                <tbody>
                  {data?.DadosAbertosPrevidenciario &&
                    data?.DadosAbertosPrevidenciario.length > 0 &&
                    data?.DadosAbertosPrevidenciario.map((item, key) => (
                      <tr key={key}>
                        <td>{item?.cpf_cnpj}</td>
                        <td>{item?.data_inscricao}</td>
                        <td>{item?.indicador_ajuizado}</td>
                        <td>{item?.nome_devedor}</td>
                        <td>{item?.numero_inscricao}</td>
                        <td>{item?.receita_principal}</td>
                        <td>{item?.situacao_inscricao}</td>
                        <td>{item?.tipo_devedor}</td>
                        <td>{item?.tipo_pessoa}</td>
                        <td>{item?.tipo_situacao_inscricao}</td>
                        <td>{item?.uf_unidade_responsavel}</td>
                        <td>{item?.unidade_responsavel}</td>
                        <td>
                          {new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(item?.VALOR_CONSOLIDADO)}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
      </div>
    </div>
  );
};
