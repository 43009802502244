import React from "react";

export const Header = (props) => (
  <div className="col-12 row justify-content-center no-gutters" id="header">
    <div className="col-8 text-center">
      <img
        src="https://sbcredito.com.br/wp-content/uploads/2020/06/logo.png"
        style={{
          padding: "10px",
          textAlign: "center",
        }}
        alt=""
      />
    </div>
  </div>
);
