export function cnpjValidation(value) {
  if (!value) return false

  // Aceita receber o valor como string, número ou array com todos os dígitos
  const isString = typeof value === 'string'
  const validTypes = isString || Number.isInteger(value) || Array.isArray(value)

  // Elimina valor em formato inválido
  if (!validTypes) return false

  // Filtro inicial para entradas do tipo string
  if (isString) {
    // Limita ao máximo de 18 caracteres, para CNPJ formatado
    if (value.length > 18) return false

    // Teste Regex para veificar se é uma string apenas dígitos válida
    const digitsOnly = /^\d{14}$/.test(value)
    // Teste Regex para verificar se é uma string formatada válida
    const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value)

    // Se o formato é válido, usa um truque para seguir o fluxo da validação
    // Se não, retorna inválido
    if (digitsOnly || validFormat) {
      //
    } else {
      return false
    }
  }

  // Guarda um array com todos os dígitos do valor
  const match = value.toString().match(/\d/g)
  const numbers = Array.isArray(match) ? match.map(Number) : []

  // Valida a quantidade de dígitos
  if (numbers.length !== 14) return false
  
  // Elimina inválidos com todos os dígitos iguais
  const items = [...new Set(numbers)]
  if (items.length === 1) return false

  // Cálculo validador
  const calc = (x) => {
    const slice = numbers.slice(0, x)
    let factor = x - 7
    let sum = 0

    for (let i = x; i >= 1; i--) {
      const n = slice[x - i]
      sum += n * factor--
      if (factor < 2) factor = 9
    }

    const result = 11 - (sum % 11)

    return result > 9 ? 0 : result
  }

  // Separa os 2 últimos dígitos de verificadores
  const digits = numbers.slice(12)
  
  // Valida 1o. dígito verificador
  const digit0 = calc(12)
  if (digit0 !== digits[0]) return false

  // Valida 2o. dígito verificador
  const digit1 = calc(13)
  return digit1 === digits[1]
}

export function build_cnpj_from_12_digits(cnpj, cgcFil) {

    let cgcNum;
    let cgcDig1;
    let cgcDig2;
    let cgcDig3;
    let cgcDig4;
    let cgcDig5;
    let cgcDig6;
    let cgcDig7;
    let cgcDig8;
    let cgcDig9;
    let cgcDig10;
    let cgcDig11;
    let cgcDig12;
    let b1;
    let b2;
    let b3;
    let b4;
    let b5;
    let b6;
    let b7;
    let b8;
    let b9;
    let b10;
    let b11;
    let b12;
    let cgcPriDig;
    let cgcSegDig;
    let cgcDV;
    let cgcList;
    let index = 1;

    cgcNum = cnpj.substring(0,12);
    cgcNum = cgcNum.substring(0, cgcNum.length-1) + "1";

    if(cgcNum.length !== 12 || cgcFil.length > 4){
      return null;
    }

    cgcDig1 = cgcNum.slice(11);
    cgcDig2 = cgcNum.slice(10,11);
    cgcDig3 = cgcNum.slice(9,10);
    cgcDig4 = cgcNum.slice(8,9);
    cgcDig5 = cgcNum.slice(7,8);
    cgcDig6 = cgcNum.slice(6,7);
    cgcDig7 = cgcNum.slice(5,6);
    cgcDig8 = cgcNum.slice(4,5);
    cgcDig9 = cgcNum.slice(3,4);
    cgcDig10 = cgcNum.slice(2,3);
    cgcDig11 = cgcNum.slice(1,2);
    cgcDig12 = cgcNum.slice(0,1);

    b1 = parseInt(cgcDig1);
    b2 = parseInt(cgcDig2);
    b3 = parseInt(cgcDig3);
    b4 = parseInt(cgcDig4);
    b5 = parseInt(cgcDig5);
    b6 = parseInt(cgcDig6);
    b7 = parseInt(cgcDig7);
    b8 = parseInt(cgcDig8);
    b9 = parseInt(cgcDig9);
    b10 = parseInt(cgcDig10);
    b11 = parseInt(cgcDig11);
    b12 = parseInt(cgcDig12);

    cgcPriDig = (b1*9+b2*8+b3*7+b4*6+b5*5+b6*4+b7*3+b8*2+b9*9+b10*8+b11*7+b12*6)%11;
    if (cgcPriDig === 10) cgcPriDig = 0;

    cgcSegDig = (cgcPriDig*9+b1*8+b2*7+b3*6+b4*5+b5*4+b6*3+b7*2+b8*9+b9*8+b10*7+b11*6+b12*5)%11;
    if (cgcSegDig === 10) cgcSegDig = 0;

    cgcDV = cgcPriDig*10+cgcSegDig;
    if (cgcDV === 0) cgcDV="00";
    if (cgcDV > 0 && cgcDV < 10) cgcDV = "0" + cgcDV;

    cgcList = cgcNum + cgcDV + " ";

    while (index < cgcFil) {

        cgcNum = cgcNum-0;                        /* converter a string em n�mero */
        cgcNum = cgcNum+1;                        /* somar 1 no looping */
        cgcNum = cgcNum.toString();               /* reverter o n�mero para string */ 
        cgcNum = "00000000000" + cgcNum;          /* recuperar os zeros � esquerda, */
        cgcNum = cgcNum.slice(cgcNum.length-12);  /* que foram perdidos na 1� linha */ 

        cgcDig1 = cgcNum.slice(11);
        cgcDig2 = cgcNum.slice(10,11);
        cgcDig3 = cgcNum.slice(9,10);
        cgcDig4 = cgcNum.slice(8,9);
        cgcDig5 = cgcNum.slice(7,8);
        cgcDig6 = cgcNum.slice(6,7);
        cgcDig7 = cgcNum.slice(5,6);
        cgcDig8 = cgcNum.slice(4,5);
        cgcDig9 = cgcNum.slice(3,4);
        cgcDig10 = cgcNum.slice(2,3);
        cgcDig11 = cgcNum.slice(1,2);
        cgcDig12 = cgcNum.slice(0,1);
        b1 = parseInt(cgcDig1);
        b2 = parseInt(cgcDig2);
        b3 = parseInt(cgcDig3);
        b4 = parseInt(cgcDig4);
        b5 = parseInt(cgcDig5);
        b6 = parseInt(cgcDig6);
        b7 = parseInt(cgcDig7);
        b8 = parseInt(cgcDig8);
        b9 = parseInt(cgcDig9);
        b10 = parseInt(cgcDig10);
        b11 = parseInt(cgcDig11);
        b12 = parseInt(cgcDig12);

        cgcPriDig = (b1*9+b2*8+b3*7+b4*6+b5*5+b6*4+b7*3+b8*2+b9*9+b10*8+b11*7+b12*6)%11;
        if (cgcPriDig === 10) cgcPriDig=0;

        cgcSegDig = (cgcPriDig*9+b1*8+b2*7+b3*6+b4*5+b5*4+b6*3+b7*2+b8*9+b9*8+b10*7+b11*6+b12*5)%11;
        if (cgcSegDig === 10) cgcSegDig=0;

        cgcDV = cgcPriDig*10 + cgcSegDig;
        if (cgcDV === 0) cgcDV = "00";
        if (cgcDV > 0 && cgcDV < 10) cgcDV = "0" + cgcDV;

        cgcList = cgcList + cgcNum + cgcDV + " ";

        index += 1;
    }

    return cgcList.trim().split(" ");
};