import React from "react";
import { AiOutlineAlert } from "react-icons/ai";

export const Protestos = (props) => {
  
  const { data } = props;

  if (data.resumo === null) {
    return (
      <div className="row justify-content-center text-center col-10">
        <div className="row justify-content-center text-center col-10 align-items-center">
          <div className="col-auto">
            <AiOutlineAlert style={{ fontSize: "100px", color: "#f8fc5d" }} />
          </div>
          <div className="col-auto bn-card-no">
            <h1 className="value titleFix" style={{ fontSize: "2.5em" }}>
              FALTA DE INFORMAÇÃO
            </h1>
          </div>
        </div>
      </div>
    )
  }

  if (data?.resumo && data?.resumo?.bool_protestos === 0) {
    return (
      <div className="row justify-content-center text-center col-10">
        <div className="row justify-content-center text-center col-10 align-items-center">
          <div className="col-auto">
            <AiOutlineAlert style={{ fontSize: "100px", color: "#b7ee81" }} />
          </div>
          <div className="col-auto bn-card-no">
            <h1 className="value titleFix" style={{ fontSize: "2.5em" }}>
              SEM PROTESTOS
            </h1>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="row justify-content-center text-center col-10">
      <div className="row justify-content-center text-center col-12s align-items-center">
        <div className="col-auto">
          <AiOutlineAlert style={{ fontSize: "100px", color: "#ea6363" }} />
        </div>

        <div className="col-6 bn-card">
          <h6>Valor Total do Protestado</h6>
          <h1 className="value">
            {new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(data?.resumo?.valor_total_protestado)}
          </h1>
        </div>

        <div className="col-auto bn-card">
          <h6>Qtde. de Protestos</h6>
          <h1 className="value titleFix">{data?.resumo?.numero_protestos}</h1>
        </div>

        <div className="col-12 tableProtestos">
          <div
            className="label"
            style={{
              fontWeight: "bolder",
              textTransform: "uppercase",
              fontSize: "12px",
              marginBottom: "5px",
            }}
          >
            Cartórios
          </div>
          <table className="table table-sm table-striped text-left">
            <thead className="thead-dark">
              <td>Nome</td>
              <td>Endereço</td>
              <td>N. Protestos</td>
              <td>Telefone</td>
            </thead>
            <tbody>
              {data?.cartorios &&
                data?.cartorios?.endereco.length > 0 &&
                data?.cartorios?.endereco.map((item, key) => (
                  <tr key={key}>
                    <td>{data?.cartorios?.nome[key]}</td>
                    <td>{item}</td>
                    <td>{data?.cartorios?.numProtestos[key]}</td>
                    <td>{data?.cartorios?.telefone[key]}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div className="col-12 tableProtestos">
          <div
            className="label"
            style={{
              fontWeight: "bolder",
              textTransform: "uppercase",
              fontSize: "12px",
              marginBottom: "5px",
            }}
          >
            Detalhado
          </div>
          <table className="table table-sm table-striped text-left tableCartorio">
            <thead className="thead-dark">
              <td>Cartório</td>
              <td>UF</td>
              <td>Data</td>
              <td>Valor</td>
            </thead>
            <tbody>
              {data?.detalhado &&
                data?.detalhado?.cartorio.length > 0 &&
                data?.detalhado?.cartorio.map((item, key) => (
                  <tr key={key}>
                    <td>{item}</td>
                    <td>{data?.detalhado?.uf[key]}</td>
                    <td>{data?.detalhado?.data[key]}</td>
                    <td>
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(data?.detalhado?.valor[key])}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
};
