/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import { Header } from "./components/header";
import { InputHeader } from "./components/inputsHeader";
import { Section } from "./components/section";
import { Footer } from "./components/footer";
import { Protestos } from "./sections/protestos";
import { ReceitaFederal } from "./sections/receitaFederal";
import { IBGE } from "./sections/ibge";
import { RJ } from "./sections/rj";
import { Processos } from "./sections/processos";
import { PGFN } from "./sections/pgfn";
import { Scores } from "./sections/scores";
import { cnpjValidation, build_cnpj_from_12_digits } from "./utils/cnpj"

import Swal from "sweetalert2";

const scheduleUrl = "https://psmzeahj6j.execute-api.us-east-1.amazonaws.com/Prod/v1/sacados/start";
const statusUrl = "https://psmzeahj6j.execute-api.us-east-1.amazonaws.com/Prod/v1/sacados/status?execution_id=";

const App = () => {

  // React state
  const [jobId, setJobId] = React.useState(null);
  const [apiResponse, setApiResponse] = React.useState(null);

  // Schedule api function
  const scheduleApi = (sacado, cedente) => {

    // Parse document string
    sacado = sacado.replace(".", "").replace(".", "").replace("/", "").replace("-", "");
    cedente = cedente.replace(".", "").replace(".", "").replace("/", "").replace("-", "");

    // Verify if document is valid
    const isValidSacado = cnpjValidation(sacado);
    const isValidCedente = cnpjValidation(cedente);

    if (isValidSacado === false || isValidCedente === false) {
      Swal.fire(
        "Documento inválido",
        "Verifique se os dados foram digitados corretamente.",
        "error"
      );
      return;
    }

    // Reconstrói CNPJ
    const cnpjSacado = build_cnpj_from_12_digits(sacado, 5)[0];
    const cnpjCedente = build_cnpj_from_12_digits(cedente, 5)[0];

    if (cnpjSacado === null || cnpjCedente === null) {
      Swal.fire(
        "Documento inválido",
        "Verifique se os dados foram digitados corretamente.",
        "error"
      );
      return;
    }

    // Build payload
    const payloadApi = JSON.stringify({
      docs: [
        {doc: cnpjCedente, type: "cedente"},
        {doc: cnpjSacado, type: "sacado"}
      ]
    })

    // Request rest api to schedule query
    const scheduleRequest = (url, payload) => {
      fetch(url, {method: 'post', body: payload})
        .then((res) => res.json())
        .then((res) => {
          setJobId(res['execution-id'])
          Swal.close();
        })
        .catch((e) => {
          console.error(e)
          Swal.fire(
            "Erro",
            "Não foi possível obter os dados no servidor Boa Nota.",
            "error"
          );
        });
    };

    // Fire swal
    Swal.fire({
      title: "Carregando Informações",
      text: "Por favor aguarde alguns instantes",
      footer: "Powered by Boa Nota - www.boanota.credit",
      icon: "info",
      showConfirmButton: false,
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
        scheduleRequest(scheduleUrl, payloadApi);
      }
    });

  };

  // Trigger when jobId state changes
  useEffect(() => {

    if (jobId === null) {
      return;
    }

    let url = statusUrl + jobId;
    
    const fetchStatus = async (url) => {
      await fetch(url)
        .then((res) => res.json())
        .then(async (res) => {
          if (res.status === 'SUCCEEDED' && res.output.statusCode === 200) {
            Swal.close();
            const resp_out = await fetch(res.output.body.presigned_url).then(response => response.json());
            setApiResponse({data: resp_out});
          } else if (res.status === 'FAILED') {
            Swal.fire(
              "Erro",
              "Não foi possível processar os dados no servidor Boa Nota.",
              "error"
            );
            return;
          } else {
            setTimeout(() => { fetchStatus(url); }, 5000);
          }
        })
        .catch((e) => {
          console.error(e);
          Swal.fire(
            "Erro",
            "Não foi possível processar os dados no servidor Boa Nota.",
            "error"
          );
          return;
        });
    };

    // Fire swal
    Swal.fire({
      title: "Processando",
      text: "Por favor aguarde alguns instantes",
      footer: "Powered by Boa Nota - www.boanota.credit",
      icon: "info",
      showConfirmButton: false,
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
        fetchStatus(url);
      }
    });

  }, [jobId])

  return (
    <div className="container-fluid h-100 reset">
      <Header />
      <InputHeader loadAPI={scheduleApi} />

      {apiResponse ? (
        <div className="row no-gutters justify-content-center " id="protestos">
          <div
            className="col-10 row justify-content-center"
            style={{ margin: "30px 0" }}
          >
            <div
              className="col-5 lineBottom"
              style={{
                borderBottom: "1px solid green",
                position: "relative",
                top: "-20px",
              }}
            />
            <div
              className="col-2 text-center"
              style={{ background: "#2F6562", color: "white", padding: "10px" }}
            >
              SCORES
            </div>
            <div
              className="col-5 lineBottom"
              style={{
                borderBottom: "1px solid green",
                position: "relative",
                top: "-20px",
              }}
            />
          </div>

          <Scores data={apiResponse.data.scores} data2={apiResponse.data} />

          <div
            className="col-10 row justify-content-center"
            style={{ margin: "30px 0" }}
          >
            <div
              className="col-5 lineBottom"
              style={{
                borderBottom: "1px solid green",
                position: "relative",
                top: "-20px",
              }}
            />
            <div
              className="col-2 text-center"
              style={{ background: "#2F6562", color: "white", padding: "10px" }}
            >
              SACADO
            </div>
            <div
              className="col-5 lineBottom"
              style={{
                borderBottom: "1px solid green",
                position: "relative",
                top: "-20px",
              }}
            />
          </div>

          <Section title="Protestos" type="sacado">
            <Protestos data={apiResponse.data.sacado.protesto} />
          </Section>
          <Section title="Receita Federal" type="sacado">
            <ReceitaFederal data={apiResponse.data.sacado.receita_federal} />
          </Section>
          <Section title="Recuperação Judicial" type="sacado">
            <RJ data={apiResponse.data.sacado.recuperacao_judicial} />
          </Section>
          {apiResponse.data.sacado.processos && (
            <Section title="Processos" type="sacado">
              <Processos data={apiResponse.data.sacado.processos} />
            </Section>
          )}
          <Section title="PGFN" type="sacado">
            <PGFN data={apiResponse.data.sacado.pgfn} />
          </Section>
          {apiResponse.data.sacado.ibge && (
            <Section title="IBGE" type="sacado">
              <IBGE data={apiResponse.data.sacado.ibge} />
            </Section>
          )}

          <div
            className="col-10 row justify-content-center"
            style={{ margin: "30px 0" }}
          >
            <div
              className="col-5 lineBottom"
              style={{
                borderBottom: "1px solid green",
                position: "relative",
                top: "-20px",
              }}
            />
            <div
              className="col-2 text-center"
              style={{ background: "#2F6562", color: "white", padding: "10px" }}
            >
              CEDENTE
            </div>
            <div
              className="col-5 lineBottom"
              style={{
                borderBottom: "1px solid green",
                position: "relative",
                top: "-20px",
              }}
            />
          </div>

          <Section title="Protestos" type="cedente">
            <Protestos data={apiResponse.data.cedente.protesto} />
          </Section>
          <Section title="Receita Federal" type="cedente">
            <ReceitaFederal data={apiResponse.data.cedente.receita_federal} />
          </Section>
          <Section title="Recuperação Judicial" type="cedente">
            <RJ data={apiResponse.data.cedente.recuperacao_judicial} />
          </Section>
          {apiResponse.data.cedente.processos && (
            <Section title="Processos" type="cedente">
              <Processos data={apiResponse.data.cedente.processos} />
            </Section>
          )}
          <Section title="PGFN" type="cedente">
            <PGFN data={apiResponse.data.cedente.pgfn} />
          </Section>
          {apiResponse.data.cedente.ibge && (
            <Section title="IBGE" type="cedente">
              <IBGE data={apiResponse.data.cedente.ibge} />
            </Section>
          )}

          <Footer version="1.0.0" />
        </div>
      ) : (
        <div className="row justify-content-center align-items-center no-gutters alertText">
          Insira o CNPJ do Sacado e o CNPJ do Cedente para exibir as informações
        </div>
      )}
    </div>
  );
};
export default App;
