import React from "react";
import { AiOutlineAlert } from "react-icons/ai";

export const RJ = (props) => {
  const { data } = props;

  return (
    <div className="row justify-content-center text-center col-10">
      {data ? (
        <div className="row justify-content-center text-center col-10">
          <div className="col-auto">
            <AiOutlineAlert style={{ fontSize: "100px", color: "#ea6363" }} />
          </div>

          <div className="col-auto bn-card">
            <h6>Valor Total do Protestado</h6>
            <h1 className="value">{data?.valor_total_protestado}</h1>
          </div>

          <div className="col-auto bn-card">
            <h6>Qtde. de Protestos</h6>
            <h1 className="value titleFix">{data?.numero_protestos}</h1>
          </div>
        </div>
      ) : (
        <div className="row justify-content-center text-center col-10">
          <div className="col-auto">
            <AiOutlineAlert style={{ fontSize: "100px", color: "#b7ee81" }} />
          </div>

          <div className="col-auto bn-card-no">
            <h1 className="value titleFix" style={{ fontSize: "2.5em" }}>
              SEM RJ
            </h1>
          </div>
        </div>
      )}
    </div>
  );
};
