import React from "react";
import { ResponsivePie } from "@nivo/pie";

export const IBGE = (props) => {
  
  const { data } = props;

  const dataChart = [
    {
      id: "ensinoMedio",
      label: "Regular Ensino Médio",
      value: data?.REGULAR_DO_ENSINO_MEDIO
        ? Math.round(data?.REGULAR_DO_ENSINO_MEDIO * 100)
        : 0,
    },
    {
      id: "ensinoFundamental",
      label: "Regular Ensino Fundamental",
      value: data?.REGULAR_DE_ENSINO_FUNDAMENTAL
        ? Math.round(data?.REGULAR_DE_ENSINO_FUNDAMENTAL * 100)
        : 0,
    },
    {
      id: "ensinoSuperior",
      label: "Especialização de Nível Superior",
      value: data?.ESPECIALIZACAO_DE_NIVEL_SUPERIOR
        ? Math.round(data?.ESPECIALIZACAO_DE_NIVEL_SUPERIOR * 100)
        : 0,
    },
  ];

  const dataChartArea = [
    {
      id: "areaPublica",
      label: "Pública",
      value: Math.round(data?.PUBLICA * 100),
    },
    {
      id: "areaPrivada",
      label: "Privada",
      value: Math.round(data?.PARTICULAR * 100),
    },
  ];

  const dataChartComodos = [
    {
      id: "comodo1",
      label: "1 Cômodo",
      value: Math.round(data?.["1_COMODO"] * 100),
    },
    {
      id: "comodo21",
      label: "2 Cômodos",
      value: Math.round(data?.["2_COMODOS"] * 100),
    },
    {
      id: "comodo3",
      label: "3 Cômodos",
      value: Math.round(data?.["3_COMODOS"] * 100),
    },
    {
      id: "comodo4",
      label: "4 Cômodos",
      value: Math.round(data?.["4_COMODOS"] * 100),
    },
    {
      id: "comodo5",
      label: "5 Cômodos",
      value: Math.round(data?.["5_COMODOS"] * 100),
    },
    {
      id: "comodo6",
      label: "6 Cômodos",
      value: Math.round(data?.["6_COMODOS"] * 100),
    },
    {
      id: "comodo7",
      label: "7 Cômodos",
      value: Math.round(data?.["7_COMODOS"] * 100),
    },
    {
      id: "comodo8",
      label: "8 Cômodos",
      value: Math.round(data?.["8_COMODOS"] * 100),
    },
    {
      id: "comodo9",
      label: "9 Cômodos",
      value: Math.round(data?.["9_COMODOS"] * 100),
    },
    {
      id: "comodo10",
      label: "10 Cômodos",
      value: Math.round(data?.["10_COMODOS"] * 100),
    },
  ];

  return (
    <div className="row justify-content-start text-left col-12 bncardFields">
      <div className="subsection col-12">
        <div className="row justify-content-start">
          <div className="fields col-7">
            <div className="col-12">
              <h3>População, Área Total e Densidade Demográfica</h3>
            </div>

            <div className="field">
              <div className="label">Residente (estimativa)</div>
              <div className="value">
                {data?.POPULACAO_RESIDENTE_ESTIMADA.toLocaleString("pt-BR")}
              </div>
              <div className="desc">Número estimado de população residente</div>
            </div>

            <div className="field">
              <div className="label">Área Total</div>
              <div className="value">
                {data?.AREA_TOTAL_DAS_UNIDADES_TERRITORIAIS.toLocaleString(
                  "pt-BR"
                )}
              </div>
              <div className="desc">Área total das unidades Territoriais</div>
            </div>

            <div className="field">
              <div className="label">Densidade Geográfica</div>
              <div className="value">
                {data?.DENSIDADE_DEMOGRAFICA_DA_UNIDADE_TERRITORIAL.toLocaleString(
                  "pt-BR"
                )}
              </div>
            </div>
          </div>

          <div className="fields col-5">
            <div className="col-12">
              <h3>Tratamento de Água</h3>
            </div>

            {data?.["NUMERO_DE_ESTACAOS_DE_TRATAMENTO"] && (
              <div className="field">
                <div className="label">QTDE ESTAÇÕES</div>
                <div className="value">
                  {data?.["NUMERO_DE_ESTACOES_DE_TRATAMENTO"].toLocaleString(
                    "pt-BR"
                  )}
                </div>
                <div className="desc">Número estações de tratamento</div>
              </div>
            )}

            {data?.["PRODUCAO_TOTAL_DAS_ESTACOES_DE_TRATAMENTO"] && (
              <div className="field">
                <div className="label">PRODUÇÃO TOTAL</div>
                <div className="value">
                  {data?.[
                    "PRODUCAO_TOTAL_DAS_ESTACOES_DE_TRATAMENTO"
                  ].toLocaleString("pt-BR")}
                </div>
                <div className="desc">
                  Produção total das estações de tratamento
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="divisor" />

        <div className="row justify-content-center">
          <div className="fields col-6">
            <div className="col-12">
              <h3>Educação</h3>
              <div
                className="fieldChart"
                style={{ width: "100%", height: "450px" }}
              >
                <ResponsivePie
                  data={dataChart}
                  margin={{ top: 0, right: 0, bottom: 80, left: 80 }}
                  innerRadius={0.5}
                  padAngle={0.7}
                  cornerRadius={3}
                  colors={{ scheme: "pastel1" }}
                  borderWidth={1}
                  borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                  radialLabelsSkipAngle={10}
                  radialLabelsTextXOffset={6}
                  radialLabelsTextColor="#333333"
                  radialLabelsLinkOffset={0}
                  radialLabelsLinkDiagonalLength={16}
                  radialLabelsLinkHorizontalLength={24}
                  radialLabelsLinkStrokeWidth={1}
                  radialLabelsLinkColor={{ from: "color" }}
                  slicesLabelsSkipAngle={10}
                  slicesLabelsTextColor="#333333"
                  animate={true}
                  motionStiffness={90}
                  motionDamping={15}
                  enableSlicesLabels={false}
                  legends={[
                    {
                      anchor: "bottom",
                      direction: "row",
                      translateY: 56,
                      itemWidth: 170,
                      itemHeight: 18,
                      itemTextColor: "#999",
                      symbolSize: 18,
                      symbolShape: "circle",
                      effects: [
                        {
                          on: "hover",
                          style: {
                            itemTextColor: "#000",
                          },
                        },
                      ],
                    },
                  ]}
                />
              </div>
            </div>
          </div>

          <div className="fields col-6">
            <div className="col-12">
              <h3>Cômodos</h3>
            </div>

            <div
              className="fieldChart"
              style={{ width: "100%", height: "450px" }}
            >
              <ResponsivePie
                data={dataChartComodos}
                margin={{ top: 0, right: 0, bottom: 80, left: 80 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                colors={{ scheme: "pastel1" }}
                borderWidth={1}
                borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                radialLabelsSkipAngle={10}
                radialLabelsTextXOffset={6}
                radialLabelsTextColor="#333333"
                radialLabelsLinkOffset={0}
                radialLabelsLinkDiagonalLength={16}
                radialLabelsLinkHorizontalLength={24}
                radialLabelsLinkStrokeWidth={1}
                radialLabelsLinkColor={{ from: "color" }}
                slicesLabelsSkipAngle={10}
                slicesLabelsTextColor="#333333"
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                enableSlicesLabels={false}
                legends={[
                  {
                    anchor: "bottom",
                    direction: "row",
                    translateY: 56,
                    itemWidth: 80,
                    itemHeight: 18,
                    itemTextColor: "#999",
                    symbolSize: 18,
                    symbolShape: "circle",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemTextColor: "#000",
                        },
                      },
                    ],
                  },
                ]}
              />
            </div>
          </div>
        </div>

        <div className="divisor" />

        <div className="row justify-content-start">
          <div className="fields col-5">
            <div className="col-12">
              <h3>Outros Indicadores</h3>
            </div>

            <div className="field">
              <div className="label">PIB</div>
              <div className="value">
                {data?.[
                  "PRODUTO_INTERNO_BRUTO_A_PRECOS_CORRENTES"
                ].toLocaleString("pt-BR")}
              </div>
              <div className="desc">
                Produto interno bruto à preços correntes
              </div>
            </div>

            <div className="field">
              <div className="label">Área Agro</div>
              <div className="value">
                {data?.["AREA_PLANTADA_OU_DESTINADA_A_COLHEITA"] &&
                  data?.["AREA_PLANTADA_OU_DESTINADA_A_COLHEITA"].toLocaleString(
                    "pt-BR"
                  )}
              </div>
              <div className="desc">Área plantada ou destinada a colheita</div>
            </div>

            <div className="field">
              <div className="label">Rendimento Médio da Produção</div>
              <div className="value">
                {data?.["RENDIMENTO_MEDIO_DA_PRODUCAO"]}
              </div>
            </div>

            <div className="field">
              <div className="label">Valor Médio</div>
              <div className="value">
                {data?.[
                  "VALOR_DO_RENDIMENTO_NOMINAL_MEDIO_MENSAL_DAS_PESSOAS_DE_10_ANOS_OU_MAIS_DE_IDADE_COM_RENDIMENTO"
                ].toLocaleString("pt-BR")}
              </div>
              <div className="desc">
                Valor do rendimento nominal médio mensal das pessoas de 10 ou
                mais anos de idade com rendimento
              </div>
            </div>
          </div>

          <div className="fields col-7">
            <div className="col-12">
              <h3>Área</h3>
            </div>
            <div
              className="fieldChart"
              style={{ width: "100%", height: "450px" }}
            >
              <ResponsivePie
                data={dataChartArea}
                margin={{ top: 0, right: 0, bottom: 80, left: 80 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                colors={{ scheme: "pastel1" }}
                borderWidth={1}
                borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                radialLabelsSkipAngle={10}
                radialLabelsTextXOffset={6}
                radialLabelsTextColor="#333333"
                radialLabelsLinkOffset={0}
                radialLabelsLinkDiagonalLength={16}
                radialLabelsLinkHorizontalLength={24}
                radialLabelsLinkStrokeWidth={1}
                radialLabelsLinkColor={{ from: "color" }}
                slicesLabelsSkipAngle={10}
                slicesLabelsTextColor="#333333"
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                enableSlicesLabels={false}
                legends={[
                  {
                    anchor: "bottom",
                    direction: "row",
                    translateY: 56,
                    itemWidth: 90,
                    itemHeight: 18,
                    itemTextColor: "#999",
                    symbolSize: 18,
                    symbolShape: "circle",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemTextColor: "#000",
                        },
                      },
                    ],
                  },
                ]}
              />
            </div>
          </div>
        </div>

        <div className="divisor" />
      </div>
    </div>
  );
};
