import React from "react";

export const Section = (props) => {
  return (
    <div
      className="col-sm-10 col-11 row baseField"
      style={{ backgroundColor: props.color }}
    >
      <div className="sectionType">{props.type}</div>
      <h1 className="col-12 title">{props.title}</h1>
      <div className="col-12 row justify-content-center">{props.children}</div>
    </div>
  );
};
