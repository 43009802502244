import React from "react";

export const Footer = (props) => (
  <div className="col-sm-10 row" style={{ marginBottom: "50px" }}>
    <div
      style={{
        width: "100px",
        textAlign: "center",
        marginTop: "10px",
        display: "block",
      }}
    >
      <img src="/img/logo.svg" alt="" />
      <div
        style={{
          display: "block",
          fontWeight: "800",
          textAlign: "left",
          fontSize: ".8em",
          color: "#00000020",
        }}
      >
        {props.version}
      </div>
    </div>
  </div>
);
